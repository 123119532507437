<script setup lang="ts">
const snackbar = useSnackbar()

function update(e: boolean) {
  snackbar.isVisible = e
}
</script>

<template>
  <VSnackbar
    v-model="snackbar.isVisible"
    :timeout="snackbar.timeout"
    color="secondary"
    location="top end"
    close-on-content-click
    @update:model-value="update"
  >
    <div style="display: flex; flex-direction: row; align-items: center">
      <div class="mr-2"><VIcon :icon="snackbar.icon" /></div>
      <div>{{ snackbar.message }}</div>
    </div>
  </VSnackbar>
</template>
